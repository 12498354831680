import React from "react"
import { RadioGroupsField } from "../fields"
import { FormRadioData } from "../../../config/config-data"

const transformOptions = (options) =>
  options.map(({ form_radio_sub_category, form_radio_price, form_radio_hide_price, form_radio_image }, i) => ({
    label: form_radio_sub_category,
    id: `${form_radio_sub_category}-${i}`,
    price: form_radio_price,
    hidePrice: form_radio_hide_price,
    value: form_radio_price ? `${form_radio_sub_category} ---- ${form_radio_price}` : form_radio_sub_category,
    image: form_radio_image
  })
);

const getCategories = () =>
  FormRadioData.map(({ form_radio_category, form_radio_help_image, form_radio_help_text, form_radio_options }) => ({
    name: form_radio_category,
    label: form_radio_category,
    helpimage: form_radio_help_image,
    helptext: form_radio_help_text,
    options: transformOptions(form_radio_options)
  })
);

const Step1 = () => {
  return (
    <div id="leadform_1662793790754">
      <RadioGroupsField
        required="Warning: Choose At Least One Option"
        groups={getCategories()}
      />
    </div>
  );
};

export default Step1;
